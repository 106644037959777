
.container{
  display:flex;
 flex-direction:column;
  margin-top:5%;
  justify-content: center;
  align-items:center;
 };

 .container-title {
  width: 500px;
  margin-top: 2%;
 }

.title-content {
  text-align: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  word-spacing: 5px;
  font-weight:bold;
}

.box{
  margin-left:-5%;
  gap:10%;
  display: flex ;
  justify-content:center;
   align-items:center;
   margin-top:1%;
   
}
.merchent-box, .courier-box{
  animation: animateLeft 1s forwards ease-in-out;
  /* animation: animateLeft 2s ease-in-out; */
  transition: transform 1s ease-in-out;
  flex-direction:column;
  display: flex;
  align-items:center;
    width:250px;
     height:300px;
    }
    .merchant-image{
      width: 300px;
      height: 300px;
    }
    .courier-image{
      width: 200px;
      height: 200px;
      margin-Bottom: 10px;
    
    }

    .merchent-title-box, .courier-title-box{
      width:200px;
       border-radius:15px;
       background:#DAEFFC;
       display:flex;
        flex-direction:column;
        margin-bottom:10px
    }

    .merchent-title, .courier-title{
      color: black;
       font-size:18px;
        font-weight:500;
         margin:0;
         width:100%;
    }
    .merchants-btn, .courier-btns{
      width:180px;
    }

    .website
    {
      margin-left: -60px;
       margin-top: 40px; 
       display: flex; 
       justify-content: center;
        align-items: 3px;
        font-size: 23px;
  }

/* .btn2 {
  animation: animateLeft 1.5s forwards ease-in-out;
  transition: transform 1s ease-in-out;
} */

@keyframes animateLeft {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes animateRight {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
  
}
.link-btns {
  text-decoration: none;
}




@keyframes animate {
  0% {
    left: -20%;
  }
  100% {
    left: 110%;
  }
}
@media(min-width:768px){
  .logo-image{
    width: 300px;
  }
}
@media (max-width: 767px) {
.logo-image{
  width: 200px;
}
  .box{
    flex-direction:column;
    gap: 25px;
  }
  .container-title{
    width: 280px;
    display:flex;
    justify-content: center;
    aligh-items: center;
    /* mergin-right: 5%; */

  }
  .merchants-btn-container, .courier-btn-container{
    display: flex;
    flex-direction: row;
    gap: 5%;
    width: 280px;
  }
  .merchent-title-box{
    width: 280px;
  }
  .merchant-image{
    width: 200px;
    height: 200px;
  }
  .courier-image{
    width: 200px;
    height: 200px;
  
  }
  .title-content{
    font-weight:bold;
    font-size: 18px;
  }
  .courier-title-box{
    width: 280px;
    text-align: center;
  }
  .merchent-title, .courier-title{
    font-size: 19px;
  }
  .website
    {
      margin-left: -35px;
       margin-top: -2%; 
       margin-Bottom: 2%; 
       display: flex; 
       justify-content: center;
        align-items: center;
        font-size: 20px;
  }
}

